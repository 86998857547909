import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { App as CapacitorApp } from '@capacitor/app'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import NotFoundPage from './pages/NotFoundPage'
import PasswordReset from './pages/PasswordReset'
import Register from './pages/Register'
import Header from './components/Header'
import QRreader from './pages/QRreader'
import MyQRcode from './pages/MyQRcode'
import AllPartnersMap from './pages/AllPartnersMap'
import ReferAFriend from './pages/ReferAFriend'
import ReferAPartner from './pages/ReferAPartner'
import Campaigns from './pages/Campaigns'
import Campaign from './pages/Campaign'
import Messages from './pages/Messages'
import PrivateRoute from './components/PrivateRoute'
import TEDSWallet from './pages/TEDSWallet'
import TEDSTransactions from './pages/TEDSTransactions'
import Home from './pages/Home'
import { useSelector } from 'react-redux'
import BottomNav from './components/BottomNav'
import Settings from './pages/Settings'
import 'webrtc-adapter'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { Capacitor } from '@capacitor/core'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useSelector((state) => state.auth)
  // Check if the current path is for the apple-app-site-association file
  const isAppleAppSiteAssociation = location.pathname === '/apple-app-site-association'
  console.log('Server URL:', process.env.REACT_APP_SERVER_URL)

  // code for backbutton handling on mobile devices
  useEffect(() => {
    const backButtonHandler = (e) => {
      if (location.pathname === '/messages') {
        // Do not handle back button here, let the specific component handle it
      } else if (navigate.length > 1) {
        navigate(-1) // Go back in history
      } else {
        CapacitorApp.exitApp()
      }
    }

    CapacitorApp.addListener('backButton', backButtonHandler)

    return () => {
      CapacitorApp.removeAllListeners('backButton')
    }
  }, [navigate, location.pathname])

  useEffect(() => {
    const setupSafeArea = async () => {
      const updateSafeArea = async () => {
        if (Capacitor.getPlatform() === 'ios') {
          const { insets } = await SafeArea.getSafeAreaInsets()
          const isLandscape = window.innerWidth > window.innerHeight

          for (const [key, value] of Object.entries(insets)) {
            if (isLandscape && key === 'top') {
              document.documentElement.style.setProperty(`--safe-area-inset-${key}`, '0px')
            } else {
              document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`)
            }
          }
        } else {
          document.documentElement.style.setProperty('--safe-area-inset-top', '0px')
          document.documentElement.style.setProperty('--safe-area-inset-bottom', '0px')
          document.documentElement.style.setProperty('--safe-area-inset-left', '0px')
          document.documentElement.style.setProperty('--safe-area-inset-right', '0px')
        }
      }

      // Initial update
      await updateSafeArea()

      // Listen for changes
      SafeArea.addListener('safeAreaChanged', updateSafeArea)

      // Also listen for orientation changes
      window.addEventListener('resize', updateSafeArea)

      return () => {
        SafeArea.removeAllListeners()
        window.removeEventListener('resize', updateSafeArea)
      }
    }

    if (Capacitor.isNativePlatform()) {
      setupSafeArea()
    }
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <div className='flex flex-col min-h-screen'>
      {!isAppleAppSiteAssociation && (
        <>
          <Header />
          <div className='flex-grow container mx-auto pt-16 '>
            <HelmetProvider>
              <div className='container'>
                <Helmet>
                  <meta charSet='utf-8' />
                  <title>Loyalty Club PLC</title>
                  <link rel='canonical' href='http://mysite.com/example' />
                </Helmet>
                <Routes>
                  <Route path='/login' element={<Login />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/forgot-password' element={<PasswordReset />} />
                  <Route path='/myqr' element={<PrivateRoute />}>
                    <Route path='/myqr' element={<MyQRcode />} />
                  </Route>
                  <Route path='/' element={<PrivateRoute />}>
                    <Route path='/' element={<Home />} />
                  </Route>
                  <Route path='/privacy-policy' element={<PrivateRoute />}>
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  </Route>
                  <Route path='/reader' element={<PrivateRoute />}>
                    <Route path='/reader' element={<QRreader />} />
                  </Route>
                  <Route path='/reader' element={<PrivateRoute />}>
                    <Route path='/reader' element={<QRreader />} />
                  </Route>
                  <Route path='/refer-friends' element={<PrivateRoute />}>
                    <Route path='/refer-friends' element={<ReferAFriend />} />
                  </Route>
                  <Route path='/refer-a-partner' element={<PrivateRoute />}>
                    <Route path='/refer-a-partner' element={<ReferAPartner />} />
                  </Route>
                  <Route path='/my-campaigns' element={<PrivateRoute />}>
                    <Route path='/my-campaigns' element={<Campaigns />} />
                  </Route>
                  <Route path='/partners-map' element={<PrivateRoute />}>
                    <Route path='/partners-map' element={<AllPartnersMap />} />
                  </Route>
                  <Route path='/campaign/:campaignID' element={<PrivateRoute />}>
                    <Route path='/campaign/:campaignID' element={<Campaign />} />
                  </Route>

                  <Route path='/messages' element={<PrivateRoute />}>
                    <Route path='/messages' element={<Messages />} />
                  </Route>
                  <Route path='/teds/wallet' element={<PrivateRoute />}>
                    <Route path='/teds/wallet' element={<TEDSWallet />} />
                  </Route>
                  <Route path='/teds/transactions' element={<PrivateRoute />}>
                    <Route path='/teds/transactions' element={<TEDSTransactions />} />
                  </Route>

                  <Route path='/settings' element={<PrivateRoute />}>
                    <Route path='/settings' element={<Settings />} />
                  </Route>
                  <Route path='*' element={<PrivateRoute />}>
                    <Route path='*' element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </div>
            </HelmetProvider>
          </div>
          {user && <BottomNav />}
        </>
      )}
      <Toaster
        position='top-center'
        reverseOrder={false}
        containerStyle={{
          marginTop: 'var(--safe-area-inset-top)',
        }}
        toastOptions={{
          style: {
            padding: '10px',
            color: '#000000',
            background: '#f0effc',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)', // Modern, subtle shadow
          },
          duration: 5000,
        }}
      />
    </div>
  )
}

export default App
