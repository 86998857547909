import React from 'react'

const Modal = ({ id, children, onClose }) => {
  return (
    <>
      <input type='checkbox' id={id} className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box relative w-11/12 max-w-[90vw] lg:max-w-[1200px] p-6'>
          <label htmlFor={id} className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <div className='overflow-y-auto max-h-[80vh]'>{children}</div>
          <div className='modal-action'>
            <label htmlFor={id} className='btn' onClick={onClose}>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
